import React, { Suspense, lazy, Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Helmet from "react-helmet";

const Home = lazy(() => import("../Home/Home"));
const Search = lazy(() => import("../Search/Search"));
const Viewer = lazy(() => import("../Viewer/Viewer"));
const Path = lazy(() => import("../Path/Path"));
const Compare = lazy(() => import("../Compare/Compare"));
const Standards = lazy(() => import("../Standards/Standards"));
const Security = lazy(() => import("../Security/Security"));
const Pricing = lazy(() => import("../Pricing/Pricing"));

const Features = lazy(() => import("../Features/Features"));
const Articles = lazy(() => import("../Resources/Articles"));
const Training = lazy(() => import("../Resources/Training"));
const MismoModelViewer = lazy(() =>
  import("../MismoModelViewer/MismoModelViewer")
);
const NiemModelViewer = lazy(() =>
  import("../NiemModelViewer/NiemModelViewer")
);
const ILAD1 = lazy(() => import("../Resources/ILAD1/ILAD1"));
const ILAD2 = lazy(() => import("../Resources/ILAD2/ILAD2"));
const ILAD3 = lazy(() => import("../Resources/ILAD3/ILAD3"));
const MismoExtensions = lazy(() =>
  import("../Resources/mismo-extensions/mismo-extensions")
);

const Updates = lazy(() => import("../Updates/Updates"));

const NotFound = lazy(() => import("../NotFound/NotFound"));
const Terms = lazy(() => import("../Terms/Terms"));
const Privacy = lazy(() => import("../Privacy/Privacy"));
const HealthCheck = lazy(() => import("../HealthCheck/HealthCheck"));

export default class App extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>XML Schema Viewer</title>
          <meta
            name="description"
            content="A lightning fast and intuitive tool for viewing, searching, and comparing XML Schemas."
          />

          <meta
            name="keywords"
            content="XML Schema Viewer, W3c XML Schema Viewer, MISMO XML Viewer, MISMO Schema Viewer, MISMO Model Viewer, NIEM XML Model Viewer, ILAD, ULAD, ULDD, W3C Schema Tool, XPath Validation, XSD Compare, Schema Comparison tool"
          />
        </Helmet>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/xml-schema-viewer" />}
              />
              <Route
                exact
                path="/xml-schema-viewer"
                name="Xml Schema Viewer"
                render={() => <Home />}
              />
              <Route
                exact
                path="/security"
                name="Grid-ML Security"
                render={() => <Security />}
              />
              <Route
                exact
                path="/information-exchange-standards"
                name="Information Exchange Standards"
                render={() => <Standards />}
              />
              <Route
                exact
                path="/mismo-model-viewer"
                name="MISMO Model Viewer"
                render={() => <MismoModelViewer />}
              />
              <Route
                exact
                path="/niem-xml-viewer"
                name="NIEM XML Viewer"
                render={() => <NiemModelViewer />}
              />
              <Route
                exact
                path="/mismo-ilad-overview"
                name="Introduction to MISMO's iLad Dataset"
                render={() => <ILAD1 />}
              />
              <Route
                exact
                path="/mismo-ilad-specification"
                name="Exploring MISMO's iLAD Specification"
                render={() => <ILAD2 />}
              />
              <Route
                exact
                path="/mismo-ilad-xml-schema"
                name="Using MISMO's iLAD XML Schema Files"
                render={() => <ILAD3 />}
              />

              <Route
                exact
                path="/mismo-xml-extensions"
                name="Understanding MISMO XML Extensions"
                render={() => <MismoExtensions />}
              />

              <Route exact path="/xsd-viewer" render={() => <Viewer />} />
              <Route exact path="/xpath-validation" render={() => <Path />} />
              <Route exact path="/xsd-visualizer" render={() => <Search />} />
              <Route exact path="/xsd-diff-tool" render={() => <Compare />} />
              <Route
                exact
                path="/Features"
                name="Features"
                render={() => <Features />}
              />
              <Route
                exact
                path="/Pricing"
                name="Pricing"
                render={() => <Pricing />}
              />
              <Route
                exact
                path="/Articles"
                name="Articles"
                render={() => <Articles />}
              />
              <Route
                exact
                path="/Training"
                name="Training"
                render={() => <Training />}
              />
              <Route
                exact
                path="/Updates"
                name="Updates"
                render={() => <Updates />}
              />
              <Route exact path="/terms" render={() => <Terms />} />
              <Route exact path="/privacy" render={() => <Privacy />} />
              <Route exact path="/HealthCheck" render={() => <HealthCheck />} />
              <Route render={() => <NotFound />} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </div>
    );
  }
}
